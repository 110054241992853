import { fetchEmailInputValidation } from "../api"
import "./emailInputField.scss"
import { querySelectorNonNull } from "../../document"

export type OnErrorStatusChangeCallback = (isValid: boolean, self: object) => void

export class EmailInput {
    private readonly $emailInputElement: HTMLInputElement
    private readonly $inputHintElement: HTMLElement
    private onErrorStatusChangeCallback?: OnErrorStatusChangeCallback
    private onErrorStatusChangeCallbackCallee?: object
    private hasError = false
    private readonly useStrictValidation: boolean

    constructor(document: Document, useStrictValidation = false) {
        this.useStrictValidation = useStrictValidation
        this.$inputHintElement = querySelectorNonNull(document, ".fti_js_email_input_hint")
        this.$emailInputElement = querySelectorNonNull(document, ".fti_js_email_input")
    }

    registerEventListeners() {
        this.$emailInputElement.addEventListener("focusout", this.onFocusOut)
    }

    registerOnErrorStatusChangeCallback(callback: OnErrorStatusChangeCallback, callee: object) {
        this.onErrorStatusChangeCallback = callback
        this.onErrorStatusChangeCallbackCallee = callee
    }

    getInputValue(): string {
        return this.$emailInputElement.value
    }

    appendEmailValidationAndHint(inputChangeHandler: () => void) {
        this.$emailInputElement.addEventListener("input", inputChangeHandler)
    }

    showErrorHint(message?: string) {
        if (message != null) {
            this.$inputHintElement.innerText = message
        }

        const parentClassList = this.$inputHintElement.parentElement?.classList
        parentClassList?.remove("p_input--hint")
        parentClassList?.add("p_input--invalid")
        this.$inputHintElement.style.display = "block"
    }

    hideErrorHint() {
        this.hideHint("p_input--invalid")
    }

    showInfoHint(hintText: string) {
        this.$inputHintElement.parentElement?.classList.add("p_input--hint")
        this.$inputHintElement.innerText = hintText
        this.$inputHintElement.style.display = "block"
    }

    hideInfoHint() {
        this.hideHint("p_input--hint")
    }

    onErrorStatusChange() {
        this.onErrorStatusChangeCallback?.call(this, !this.hasError, this.onErrorStatusChangeCallbackCallee)
    }

    private hideHint(hintClass: string) {
        const hasHintClass = this.$inputHintElement.parentElement?.classList.contains(hintClass)
        if (hasHintClass) {
            this.$inputHintElement.style.display = "none"
            this.$inputHintElement.parentElement?.classList.remove(hintClass)
        }
    }

    private onFocusOut = () => {
        this.$emailInputElement.removeEventListener("input", this.onInput)
        this.$emailInputElement.addEventListener("input", this.onInput)
        this.validateInput().catch((e: unknown) => {
            console.error(e)
        })
    }

    private onInput = () => {
        this.validateInput().catch((e: unknown) => {
            console.error(e)
        })
    }

    private async validateInput() {
        try {
            const responseData = await fetchEmailInputValidation(this.$emailInputElement.value, this.useStrictValidation)
            const errorMessage = responseData.errorMessage
            this.hasError = errorMessage != null && errorMessage != ""
            if (this.hasError) {
                this.showErrorHint(errorMessage)
            } else {
                this.hideErrorHint()
            }

            this.onErrorStatusChange()
        } catch (exception) {
            console.error("error while talking to backend", exception)
        }
    }
}
